@import "../../styles/variables";

$header-height: 125px;
$tablet-header-height: 55px;
$header-bottom-max-height: 45px;

.app-header {
  .navbar {
    background-color: $white !important;
    padding: 0px !important;
    height: $header-height;
    .navbar-brand {
      margin: 0px;
      padding: 0px;
      img {
        box-sizing: border-box;
        width: $header-height;
        height: $header-height;
        padding: 25px 25px 20px 25px;
        background-color: $emoryblue;
      }
    }
    .navbar-toggler {
      padding: 0px;
      border: 0px;
    }
    .navbar-collapse {
      float: left !important;
    }
    .header {
      background-color: $white;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column !important;
      &-title {
        color: $emoryblue;
        text-align: left;
        margin-bottom: 0px;
        cursor: default;
        font-weight: bold;
      }
      &-top {
        text-align: left;
        padding-top: 16px;
        padding-left: 16px;
        img {
          margin-bottom: 0.5rem;
        }
      }
      &-bottom {
        padding: 0px;
        background-color: $gray-100;
        width: 100%;
        .navbar-nav {
          display: flex;
          align-items: center;
          .nav-link {
            text-align: left;
            padding: 0.25em 0.5em;
            border: 2px solid $gray-100;
            color: $emoryblue;
            background-color: $gray-100;
            font-weight: bold;
            white-space: nowrap;
            &:hover {
              color: $blue;
              border: 2px solid $blue;
            }
          }
          .nav-item {
            a {
              font-weight: bold;
              &::after {
                color: $yellow;
              }
            }
            .dropdown-menu {
              border: 1px solid $gray-200;
              border-radius: 0px;
              a:hover {
                background-color: $gray-200;
              }
              a:active {
                color: $white;
                background-color: $yellow;
              }
            }
          }
        }
      }
    }
  }

  .navbar.scroll-top {
    transition: height 0.35s;
    .navbar-brand {
      transition: width 0.35s;
      transition: height 0.35s;
      img {
        transition: width 0.35s;
      }
    }
    .header {
      &-top {
        transition: all 0.35s;
      }
      &-bottom {
        transition: height 0.35s;
        max-height: $header-bottom-max-height;
      }
    }
  }

  .navbar.scroll-down {
    transition: height 0.35s;
    height: 55px;
    .navbar-brand {
      transition: width 0.35s;
      transition: height 0.35s;
      img {
        transition: width 0.35s;
        overflow: hidden;
        box-sizing: border-box;
        width: 55px;
        height: 55px;
        padding: 4px;
        background-color: $emoryblue;
      }
    }

    .header {
      &-top {
        transition: all 0.35s;
        display: none;
      }
      &-bottom {
        transition: height 0.35s;
        height: 55px;
      }
    }
  }

  @media (max-width: $tablet) {
    .navbar {
      justify-content: left !important;
      height: $tablet-header-height;
      .navbar-brand {
        img {
          overflow: hidden;
          box-sizing: border-box;
          width: $tablet-header-height;
          height: $tablet-header-height;
          padding: 4px;
          background-color: $emoryblue;
        }
      }
      .header {
        width: calc(100% - 111px);
        &-title {
          overflow: hidden;
        }
        &-top {
          height: 55px;
          text-align: left;
          padding-top: 5px;
          padding-bottom: 5px;
          img {
            height: 35%;
            margin-bottom: 0.5rem;
          }
        }
        &-bottom {
          background-color: $white;
          // this is: calc(100% + width of brand/logo div + width of hamburger button)
          width: calc(100% + 55px + 54px);
          // brand/logo is 55px wide, this pushes div to left of screen
          margin-left: -55px;
          .navbar-nav {
            align-items: unset;
            .nav-link {
              background-color: $white !important;
            }
          }
        }
      }
      .navbar-toggler {
        border: 0px;
      }
    }

    .navbar.scroll-down {
      .header {
        &-top {
          display: block;
        }
      }
    }
  }

  @media (max-width: $mobile) {
    .navbar {
      .header {
        &-title {
          height: 29px;
        }
        &-top {
          padding-top: 13px;
          padding-bottom: 13px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
