@import "../../../shared/src/styles/variables";

#aae-footer {
  padding: 6rem 0;
  background-color: #f5f6fa;
  height: 250px;
  div {
    width: 80%;
    margin: auto;
    display: flex;
    border-top: 1px solid $emoryblue;
    padding-top: 2rem;
    ul {
      list-style: none;
      padding: 0;
      display: flex;
      justify-content: space-between;
      li {
        a {
          color: $emoryblue;
          text-decoration: none;
          font-size: 16px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    #buttons {
      margin-right: 8rem;
      a {
        border: 1px solid $emoryblue;
        font-size: 1em;
        line-height: 1em;
        justify-content: center;
        align-items: center;
        border-radius: 50rem;
        border-width: 0.15rem;
        padding: 0.5em 1rem;
        &:hover {
          background-color: $emoryblue;
          text-decoration: none;
          color: white !important;
        }
        &:last-child {
          margin-right: 1rem;
        }
      }
    }
    #links {
      a {
        font-size: 0.8em;
        &:last-child {
          margin-left: 2rem;
        }
      }
    }
  }
}
