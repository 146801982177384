.emory-pagination {
  .btn-outline-dark {
    --bs-btn-border-color: #d1d3e2;
    --bs-btn-hover-color: #000;
    --bs-btn-hover-bg: #fff;
    --bs-btn-hover-border-color: #d1d3e2;
    --bs-btn-active-color: #000;
    --bs-btn-active-bg: #fff;
    --bs-btn-active-border-color: #d1d3e2;
  }

  .input-group .btn {
    z-index: unset;
  }
}
