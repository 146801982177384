.loading {
  &-spinner {
    margin: 0;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 100000000;
  }

  &-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    display: flex;
    text-align: center;
    font-size: 1.2em;
    color: #fff;
    background: rgba(0, 0, 0, 0.7);
    z-index: 100000000;
    -webkit-transition: opacity 500ms ease-in;
    transition: opacity 500ms ease-in;
    opacity: 1;
  }
}
