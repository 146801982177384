@import "./variables";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $emoryblue;
  font-weight: bold;
}

.ReactModalPortal {
  .ReactModal__Overlay--after-open {
    z-index: 2000;
  }
}

.ReactModal__Body--open {
  overflow: hidden;
}

/**
 * Form Inputs
 */
$disabled-input-color: #000 !important;

.react-select {
  &--is-disabled {
    pointer-events: unset !important;
  }

  &__single-value--is-disabled {
    color: $disabled-input-color;
  }
}

.react-select__control,
.form-control {
  &:disabled {
    color: $disabled-input-color;
    background-color: hsl(0, 0%, 95%);
    border-color: hsl(0, 0%, 90%);
  }

  &:focus,
  &:hover {
    border: 1px solid $gray-600 !important;
  }
}
