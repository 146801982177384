@import "../../../shared/src/styles/variables";

#confirmation-message {
  text-align: center;
  h1 {
    color: white;
    background-color: $emoryblue;
    width: fit-content;
    margin: 0px auto;
    padding: 0.1em 0.25em;
    border-radius: 8px;
    font-family: serif;
    margin-bottom: 0.5em;
  }
}
