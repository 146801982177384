@import "../../../../../../../shared/src/styles/variables";

.moc-submissions__submissions-table__actions {
    .btn {
      margin-right: 0.1rem;
      margin-left: 0.1rem;
    }
  
    .btn-success {
      color: white;
      background-color: #72bb53
    }
  
    .btn-danger {
      color: white;
    }
    .btn-info {
      color: white;
    }
  }