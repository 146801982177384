@import "../../../shared/src/styles/variables";

#aae-nav {
  display: flex;
  align-items: center;
  height: 125px;
  background-color: #f5f6fa;
  margin-bottom: 1rem;
  flex-direction: row;
  justify-content: flex-end;
  .navbar-brand {
    position: absolute;
    left: 0;
    margin: 0px;
    padding: 0px;
    img {
      box-sizing: border-box;
      width: 125px;
      height: 125px;
      padding: 25px 25px 20px 25px;
      background-color: $emoryblue;
    }
  }

  #aae-nav-links {
    list-style: none;
    text-align: center;
    height: 60px;
    max-width: 1400px;
    margin: 0 0 0 125px;
    padding: 0 1rem 8px 1rem;
    border-bottom: 1px solid $emoryblue;
    .aae-nav-link {
      display: inline-flex;
      &:first-child,
      &:nth-child(2) {
        border: 1px solid $emoryblue;
        font-style: sans-serif;
        font-size: 1em;
        font-weight: 800;
        line-height: 1em;
        justify-content: center;
        align-items: center;
        border-radius: 50rem;
        border-width: 0.15rem;
        padding: 0.5em 1rem;
        a {
          width: auto;
          height: auto;
        }
        &:hover {
          background-color: $emoryblue;
          a {
            color: white;
            text-decoration: none;
          }
        }
      }
      &:nth-child(3),
      &:last-child {
        font-family: serif;
        font-size: 2rem;
      }
      &:not(:last-child) {
        margin-right: 20px;
      }
      a {
        color: $emoryblue;
        text-decoration: none;
        border: 1px solid #ffffff00;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
