@import"../../../../components/AppHeader/styles.scss";

$sidebar-position-top: $header-height;
$sidebar-position-bottom: $header-bottom-max-height;
$buttons-panel-height: $header-bottom-max-height;

.form-displayer-wrapper {
  .sidebar-wrapper {
    min-height: 100vh !important;
    -webkit-transition: margin 0.25s ease-out;
    -moz-transition: margin 0.25s ease-out;
    -o-transition: margin 0.25s ease-out;
    transition: margin 0.25s ease-out;

    .sidebar {
      width: inherit !important;
      position: fixed;
      top: 0;
      bottom: $sidebar-position-bottom;
      left: 0;
      min-height: calc(100vh - $sidebar-position-bottom) !important;
      z-index: 100;
      padding: $sidebar-position-top 0 25px;
      box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
      overflow-y: auto;
      margin-bottom: $buttons-panel-height;
    }

    .nav {
      .nav-link.active {
        font-weight: bolder;
      }
    }
  }

  .page-content-wrapper {
    min-width: 0;
    margin-bottom: $buttons-panel-height;
  }

  &-buttons-panel {
    height: $buttons-panel-height !important;
  }
}
