@import "../../styles/_bootstrap.scss";

.form {
  .form-invalid-input {
    @extend .border;
    @extend .border-danger;
    @extend .rounded;
  }

  .printable-form-control {
    white-space: pre-wrap;
  }
}
