@import "../../../../styles/variables";

.form-editor {
  &-buttons-panel {
    background-color: $gray-200 !important;
    height: auto !important;
    display: flex;
    justify-content: center !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
