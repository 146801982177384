@import "../../../shared/src/styles/variables";

#aae-events {
  form {
    h1,
    h2 {
      color: $emoryblue;
      font-size: calc(0.8em + 1.5vw);
      white-space: nowrap;
    }
    h1 {
      color: $white;
      background-color: $emoryblue;
      width: fit-content;
      padding: 0.1em 0.25em;
      border-radius: 8px;
      font-family: serif;
    }
    fieldset {
      h4,
      h5,
      p {
        color: $gray-900;
      }
      p {
        width: fit-content;
        background-color: $gray-200;
        padding: 0.25em 0.5em;
      }
      .row {
        margin: 0 0 2rem;
        .col {
          div {
            label {
              color: $black;
              font-weight: 500;
              margin: 0;
              .text-danger {
                color: $red !important;
              }
            }
            select,
            input {
              border-color: $gray-600;
              color: $gray-900;
            }
          }
        }
      }
    }
    #submit-button-row {
      margin-bottom: 2em;
    }
  }
}
